import React, { useEffect } from "react";
import Menu from "antd/es/menu";
import {
  UserOutlined,
  HomeOutlined,
  GlobalOutlined,
  UnorderedListOutlined,
  ApartmentOutlined,
  SolutionOutlined,
  SwitcherOutlined,
  InteractionOutlined,
  ShoppingOutlined,
  CommentOutlined,
  FileSyncOutlined,
  VideoCameraOutlined,
  StarOutlined,
  BellOutlined,
  InfoCircleOutlined,
  CarryOutOutlined,
  CrownOutlined,
  EnvironmentOutlined,
  ProfileOutlined,
  UserSwitchOutlined,
  LockOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd/lib/menu";
import type { SelectInfo } from "rc-menu/lib/interface";
import {
  StyledLogo as Logo,
  StyledAcademyLogo as AcademyLogo,
  StyledLogoHolder as LogoHolder,
  StyledSider as Sider,
  StyledMenu as MenuStyle,
} from "@/components/styles/sider";
import { useLocation, useNavigate } from "react-router-dom";
import { GetRequest } from "@/api/handleReactQuery";
import { urls } from "@/api/urls";

export const MenuItems: MenuProps["items"] = [
  {
    label: "Home",
    key: "/",
    icon: <HomeOutlined />,
  },
  {
    label: "Communication",
    key: "/communication",
    icon: <CommentOutlined />,
  },
  {
    label: "Meetings",
    key: "/meetings",
    icon: <CarryOutOutlined />,
  },
  {
    label: "Access Level",
    key: "/accessLevel",
    icon: <LockOutlined />,
  },
  {
    label: "Users Management",
    key: "/user",
    icon: <UserOutlined />,
  },
  {
    label: "Countries",
    key: "/baseInfo",
    icon: <GlobalOutlined />,
  },
  {
    label: "Listings",
    key: "/listing",
    icon: <UnorderedListOutlined />,
  },
  {
    label: "Categories",
    key: "/category",
    icon: <ApartmentOutlined />,
  },
  {
    label: "Posts Management",
    key: "/post",
    icon: <SolutionOutlined />,
  },
  {
    label: "Vouchers",
    key: "/vouchers",
    icon: <SwitcherOutlined />,
  },
  {
    label: "Deals",
    key: "/deals",
    icon: <InteractionOutlined />,
  },
  {
    label: "Orders",
    key: "/order",
    icon: <ShoppingOutlined />,
  },
  {
    label: "FAQ",
    key: "/faq",
    icon: <CommentOutlined />,
  },
  {
    label: "Ticket",
    key: "/ticket",
    icon: <FileSyncOutlined />,
  },
  {
    label: "Video",
    key: "/video",
    icon: <VideoCameraOutlined />,
  },
  {
    label: "Comment",
    key: "/rate",
    icon: <StarOutlined />,
  },
  {
    label: "About Us",
    key: "/frontendContent",
    icon: <InfoCircleOutlined />,
  },
  {
    label: "News",
    key: "/news",
    icon: <ProfileOutlined />,
  },
  {
    label: "Featured",
    key: "/featured",
    icon: <CrownOutlined />,
  },
  {
    label: "Australian Made",
    key: "/australianMade",
    icon: <EnvironmentOutlined />,
  },
  {
    label: "City",
    key: "/city",
    icon: <ApartmentOutlined />,
  },
  {
    label: "Membership Features",
    key: "/membershipFeatures",
    icon: <UserSwitchOutlined />,
  },
  {
    label: "Membership Type",
    key: "/membershipType",
    icon: <UserSwitchOutlined />,
  },
  {
    label: "Subscription",
    key: "/membership",
    icon: <BellOutlined />,
  },
  {
    label: "Reservation",
    key: "/booking",
    icon: <CarryOutOutlined />,
  },
  {
    label: "Quotes",
    key: "/quote",
    icon: <ProfileOutlined />,
  },
  {
    label: "Events",
    key: "/event",
    icon: <ProfileOutlined />,
  },
];

const userMenu: MenuProps["items"] = [];

export const Sidebar = () => {
  const [collapsed, setCollapsed] = React.useState(false);

  const { isLoading: authenticationLoading, refetch: mutateGetCurrentUser } =
    GetRequest(["GET_CURRENT_USER"], urls.user.current, {
      onSuccess: (data: any) => {
        fixUserMenu(data);
      },
      onError: (error: any) => {
        console.error("check login error", error);
      },
    });

  useEffect(() => {
    if (!userMenu[0]) {
      mutateGetCurrentUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fixUserMenu = async (data: any) => {
    const userRole: any[] = [];
    data.message.roles.map((x: any) => {
      x.role.permissions.map((permission: any) => {
        userRole.push(permission.resource);
      });
    });
    const uniqueUserRoles = Array.from(new Set(userRole));
    uniqueUserRoles.map((permission) => {
      MenuItems.map((defaultMenu) => {
        if ("/" + permission === defaultMenu?.key) {
          return userMenu.push(defaultMenu);
        }
      });
    });
  };

  const history = useNavigate();
  const location = useLocation();

  const onChangeSidebar = (menu: SelectInfo) => {
    history(menu.key);
  };

  const autoCollaps = (value: boolean) => {
    setCollapsed(value);
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => autoCollaps(value)}
      theme="light"
      width={250}
    >
      <MenuStyle>
        <LogoHolder>
          {localStorage.getItem("platform") === "forlocal" ? (
            <Logo />
          ) : (
            <AcademyLogo />
          )}
        </LogoHolder>
        <Menu
          defaultSelectedKeys={[location.pathname]}
          mode="vertical"
          items={userMenu}
          onSelect={onChangeSidebar}
          className="bg-transparent"
        />
      </MenuStyle>
    </Sider>
  );
};

export default Sidebar;
