import {
  StyledHeader,
  StyledProfile,
  StyledLogoutBtn,
} from "@/components/styles/header";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { UserContext } from "@/context/userContext";
import Button from "antd/lib/button";

import { useNavigate } from "react-router-dom";
import { USER_TOKEN_LOCALSTORAGE } from "@/api/types/category";

export const Header = () => {
  const navigate = useNavigate();
  const { currentUser, handleLogout } = React.useContext(UserContext);
  const [userData, setUserData] = useState<any>();

  useEffect(() => {
    const storedUser = localStorage.getItem(USER_TOKEN_LOCALSTORAGE);
    if (storedUser) {
      setUserData(JSON.parse(storedUser).user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routeChange = () => {
    navigate(`/currentUser`, { state: { currentUser } });
  };

  return (
    <StyledHeader>
      <div className="flex items-center">
        <StyledProfile>
          <Button onClick={() => routeChange()}>
            <UserOutlined className="mr-2" />
            Welcome {userData?.name}
          </Button>
        </StyledProfile>
        <StyledLogoutBtn>
          <Button className="ml-2" type="default" onClick={handleLogout}>
            <LogoutOutlined className="mr-2" />
            Logout
          </Button>
        </StyledLogoutBtn>
      </div>
    </StyledHeader>
  );
};

export default Header;
