import styled from "styled-components";
import tw from "twin.macro";
import Layout from "antd/es/layout";
const { Header } = Layout;

export const StyledHeader = styled(Header)`
  ${tw`flex items-center justify-end text-primary h-[50px] bg-white`}
  background: linear-gradient(0.15deg,#EAF0FF 1.98%,#FFFFFF 98.4%);
  box-shadow: 10px 10px 100px 0 rgb(0 0 0 / 30%);

}`;
export const StyledProfile = styled.div`
button {
    border: 1px solid;
    border-radius: 15px;
    padding: 0 20px;
    box-shadow: 0px 2px 5px #000000;
  }
}`;
export const StyledLogoutBtn = styled.div`
button {
    border: 1px solid #2e2964;
    border-radius: 15px;
    padding: 0 20px;
    box-shadow: 0px 2px 5px #000000;
    background: #2e2964;
    color: #fff;
  }
}`;
