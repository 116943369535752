import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as Logo } from "@/assets/logo-coloral.svg";
import Layout from "antd/es/layout";
import { ReactComponent as AcademyLogo } from "@/assets/Academy_Logo.svg";
const { Sider } = Layout;

export const StyledLogoHolder = styled.div`
  ${tw`flex items-center justify-center h-[50px] w-full px-3 -mt-1`}
  @media (max-width: 900px) {
    display: none;
  }
`;
export const StyledSider = styled(Sider)`
  background: linear-gradient(0.15deg, #eaf0ff 1.98%, #ffffff 98.4%);
  box-shadow: 99px 30px 200px 0 rgba(0, 0, 0, 0.1);

  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    background-color: transparent;
  }
  ${tw`table-auto md:table-fixed bg-[red]`}
  @media (max-width: 900px) {
    display: none;
  }
`;
export const StyledLogo = styled(Logo)`
  ${tw`fill-[#fff] text-[100px]`}
`;
export const StyledAcademyLogo = styled(AcademyLogo)`
  ${tw`  w-[150px] h-[150px]`}
`;
export const StyledMenu = styled.div`
  background-color: #f4f4f4;
  padding: 14px;
  display: flex;
  width: 100%;
  flex-direction: column;
  flexDirection: "column",
  justifyContent: "space-between",
  zIndex: 1,
`;
