export enum RoutesNames {
  INDEX_PAGE = "/",
  CHOOSE_PLATFORM_PAGE = "/choosePlatform",
  LOGIN_PAGE = "/login",
  USERS_PAGE = "/user",
  USER_UPDATE_PAGE = "/user/userCreateUpdatePage/:id",
  USER_FILE_PAGE = "/user/UserFiles",
  USER_ORDERS_PAGE = "/user/orders",
  USER_FAVORITE_PAGE = "/user/favorite",
  USER_EVENTS_PAGE = "/user/userEvents",
  EVENT_UPDATE_PAGE = "/userEventCreateUpdatePage",
  EVENT_RESERVATION_PAGE = "/user/userEvents/userEventReservation",
  USER_RESERVATION_PAGE = "/user/userReservation",
  USER_WISHLIST_PAGE = "/user/userWishlist/:id",
  COUNTRIES_PAGE = "/baseInfo",

  LISTINGS_PAGE = "/listing",
  LISTINGS_UPDATE_PAGE = "/listing/listingUpdatePage/:id",
  LISTINGS_SERVICES_PAGE = "/listing/services",
  LISTINGS_SERVICE_UPDATE_PAGE = "/listing/services/serviceUpdate",
  LISTINGS_STAFF_PAGE = "/listing/staff",
  LISTINGS_STAFF_UPDATE_PAGE = "/listing/staff/staffUpdate",
  LISTINGS_MENU_PAGE = "/listing/menu",
  LISTINGS_ORDERS_PAGE = "/listing/orders",
  LISTINGS_AUSTRALIAN_MADE_PAGE = "/listing/posts",
  LISTINGS_AUSTRALIAN_MADE_UPDATE_PAGE = "/listing/posts/australianMadeUpdate/:id",
  LISTINGS_DEALS_UPDATE_PAGE = "/listing/posts/dealsUpdate/:id",
  LISTINGS_VOUCHERS_UPDATE_PAGE = "/listing/posts/vouchersUpdate/:id",
  LISTINGS_NEWS_UPDATE_PAGE = "/listing/posts/newsUpdate/:id",

  CATEGORIES_PAGE = "/category",
  CATEGORY_UPDATE_PAGE = "/category/categoryUpdatePage/:id",

  POST_MANAGEMENT_PAGE = "/post",
  POST_UPDATE_PAGE = "/post/postCreateUpdatePage/:id",
  DEALS_PAGE = "/deals",
  DEALS_UPDATE_PAGE = "/deals/dealsUpdatePage/:id",
  VOUCHER_PAGE = "/vouchers",
  VOUCHERS_UPDATE_PAGE = "/vouchers/vouchersUpdatePage/:id",
  ORDER_PAGE = "/order",
  ORDER_VIEW_PAGE = "/order/orderViewPage/:id",

  FAQ_PAGE = "/faq",
  FAQ_UPDATE_PAGE = "/faq/faqCreateUpdatePage/:id",

  TICKET_PAGE = "/ticket",
  VIDEO_PAGE = "/video",
  VIDEO_VIEW_PAGE = "/video/videoUpdatePage/:id",
  RATE_PAGE = "/rate",
  ABOUT_US_PAGE = "/frontendContent",
  ABOUT_US_UPDATE_PAGE = "/frontendContent/aboutUsCreateUpdatePage/:id",

  NEWS_PAGE = "/news",
  NEWS_UPDATE_PAGE = "/news/newsUpdatePage/:id",

  FEATURED_PAGE = "/featured",
  FEATURED_UPDATE_PAGE = "/featured/featureCreateUpdatePage/:id",

  AUSTRALIAN_MADE_PAGE = "/australianMade",
  AUSTRALIAN_MADE_UPDATE_PAGE = "/australianMade/australianMadeUpdatePage/:id",
  CITY_PAGE = "/city",
  CITY_UPDATE_PAGE = "/city/cityCreateUpdatePage/:id",
  MEMBERSHIP_FEATURES_PAGE = "/membershipFeatures",
  MEMBERSHIP_FEATURES_UPDATE_PAGE = "/membershipFeatures/membershipFeaturesCreateUpdatePage/:id",
  MEMBERSHIP_TYPE_PAGE = "/membershipType",
  SUBSCRIPTION_PAGE = "/membership",
  MEMBERSHIP_TYPE_UPDATE_PAGE = "/membershipType/membershipTypeCreateUpdatePage/:id",
  RESERVATION_PAGE = "/booking",
  VENDOR_TRANSACTION_PAGE = "/vendorTransactions",
  QUOTES_PAGE = "/quote",
  EVENTS_PAGE = "/event",
  ACCESS_LEVEL_PAGE = "/accessLevel",
  CURRENT_USER_PAGE = "/currentUser",
  MEETINGS_PAGE = "/meetings",
  MEETINGS_JOIN_PAGE = "/meetings/join/:id",
  COMMUNICATIONS_PAGE = "/communication",
  COMMUNICATION_UPDATE_PAGE = "/communication/communicationCreateUpdatePage/:id",
  NotFound = "*",
}

type RouteData = {
  route: string;
  component: any;
  key: string;
  param?: string | string[];
};

export interface Routes {
  choosePlatformPage: RouteData;
  loginPage: RouteData;
  indexPage: RouteData;
  usersPage: RouteData;
  userUpdatePage: RouteData;
  userFilePage: RouteData;
  userOrdersPage: RouteData;
  userFavoritePage: RouteData;
  userEventsPage: RouteData;
  userReservationPage: RouteData;
  eventUpdatePage: RouteData;
  eventReservationPage: RouteData;
  userWishlistPage: RouteData;
  countriesPage: RouteData;
  listingsPage: RouteData;
  listingsUpdatePage: RouteData;
  listingsServicesPage: RouteData;
  listingsServiceUpdatePage: RouteData;
  listingsStaffPage: RouteData;
  listingsStaffUpdatePage: RouteData;
  listingsMenuPage: RouteData;
  listingsOrdersPage: RouteData;
  listingsAustralianMadePage: RouteData;
  listingsAustralianMadeUpdatePage: RouteData;
  listingsDealsUpdatePage: RouteData;
  listingsVouchersUpdatePage: RouteData;
  listingsNewsUpdatePage: RouteData;
  categoriesPage: RouteData;
  categoryUpdatePage: RouteData;
  postManagementPage: RouteData;
  postUpdatePage: RouteData;
  dealsPage: RouteData;
  dealsUpdatePage: RouteData;
  voucherPage: RouteData;
  vouchersUpdatePage: RouteData;
  orderPage: RouteData;
  orderViewPage: RouteData;
  faqPage: RouteData;
  faqUpdatePage: RouteData;
  ticketPage: RouteData;
  videoPage: RouteData;
  videosUpdatePage: RouteData;
  ratePage: RouteData;
  aboutUsPage: RouteData;
  aboutUsUpdatePage: RouteData;
  newsPage: RouteData;
  newsUpdatePage: RouteData;
  featuredPage: RouteData;
  featuredUpdatePage: RouteData;
  australianMadePage: RouteData;
  australianMadeUpdatePage: RouteData;
  cityPage: RouteData;
  cityUpdatePage: RouteData;
  membershipFeaturesPage: RouteData;
  membershipFeaturesUpdatePage: RouteData;
  membershipTypePage: RouteData;
  membershipTypeUpdatePage: RouteData;
  vendorTransactionsPage: RouteData;
  subscriptionPage: RouteData;
  reservationPage: RouteData;
  quotesPage: RouteData;
  eventsPage: RouteData;
  accessLevelPage: RouteData;
  currentUserPage: RouteData;
  meetingsPage: RouteData;
  meetingsJoinPage: RouteData;
  communicationsPage: RouteData;
  communicationUpdatePage: RouteData;
  notFound: RouteData;
}
