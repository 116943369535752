export const urls = {
  auth: {
    login: "/auth/login",
    searchABN: "/auth/SearchByABN",
  },
  user: {
    list: "admin/getAllUsers",
    me: "auth/currentUser",
    current: "admin/current",
    register: "admin/register",
    changePassword: "admin/changePassword",
    searchUser: "admin/searchUser",
    update: (id?: string) => `admin/updateUser/${id}`,
    single: "admin/getSingleUser",
    delete: "api/user/delete",
    getCity: "/vendor/getCity",
    getUserEvents: "/admin/getUserEvents",
    getUserReservation: "/admin/userReservations",
    getEventReservation: "/admin/eventReservations",
    getEvent: "/admin/getEvent",
    addEvent: "/admin/addEvent",
    deleteEvent: "/admin/deleteEvent",
    updateEvent: (id?: string) => `admin/updateEvent/${id}`,
  },
  countries: {
    list: "admin/getCountries",
    add: "admin/addCountry",
    update: (id?: string) => `admin/updateCountry/${id}`,
    single: "admin/getCountry",
  },
  listings: {
    list: "admin/getListings",
    add: "admin/addListing",
    update: (id?: string) => `admin/updateListing/${id}`,
    single: "admin/getSingleListing",
    search: "admin/searchListing",
    posts: "admin/getVendorPosts",
    addPost: "admin/addPost",
  },
  categories: {
    list: "admin/getCategories",
    single: "admin/getCategory",
    add: "admin/addCategory",
    update: (id?: string) => `admin/updateCategory/${id}`,
    delete: "admin/deleteCategory",
  },
  posts: {
    list: "admin/getPosts",
    single: "admin/post",
    update: (id?: string) => `admin/updatePost/${id}`,
  },
  deals: {
    list: "admin/getPosts",
    single: "admin/post",
    update: (id?: string) => `admin/updatePost/${id}`,
  },
  voucher: {
    list: "admin/getPosts",
    single: "admin/post",
    update: (id?: string) => `admin/updatePost/${id}`,
  },
  order: {
    list: "admin/getOrders",
    single: "admin/getOrder",
    chart: "admin/statistics",
  },
  faq: {
    list: "admin/allFAQ",
    single: "admin//FAQ",
    create: "admin/createFAQ",
    delete: "admin/deleteFAQ",
    update: (id?: string) => `/admin/updateFAQ/${id}`,
  },
  ticket: {
    list: "admin/tickets",
    single: "admin/ticket",
    update: (id?: string) => `admin/ticket/${id}`,
  },
  video: {
    list: "admin/getPosts",
    single: "admin/post",
    update: (id?: string) => `admin/updatePost/${id}`,
  },
  rates: {
    list: "admin/allRates",
    update: (id?: string) => `admin/updateRate/${id}`,
  },
  aboutUs: {
    list: "admin/allFrontendContents",
    update: (id?: string) => `admin/updateFrontContent/${id}`,
    single: "admin/getSingleContent",
    add: "admin/createFrontContent",
  },
  news: {
    list: "admin/getPosts",
    single: "admin/post",
    update: (id?: string) => `admin/updatePost/${id}`,
  },
  feature: {
    list: "admin/getFeaturedLists",
    single: "admin/getFeatured",
    add: "admin/addFeatured",
    update: (id?: string) => `admin/updateFeatured/${id}`,
  },
  australianMade: {
    list: "admin/getPosts",
    single: "admin/post",
    update: (id?: string) => `admin/updatePost/${id}`,
  },
  vendorTransactions: {
    list: "admin/getPosts",
    single: "admin/post",
    update: (id?: string) => `admin/updatePost/${id}`,
  },
  fileManager: {
    uploadUserFile: "/upload/uploadUserFile",
    userFile: "/admin/userFiles",
  },
  cities: {
    list: "admin/getCities",
    add: "admin/addCity",
    single: "admin/getCity",
    search: "admin/searchCity",
    update: (id?: string) => `admin/updateCity/${id}`,
  },
  MembershipFeatures: {
    list: "admin/getAllMembershipFeatures",
    add: "admin/addMembershipFeatur",
    single: "admin/getMembershipFeature",
    update: (id?: string) => `admin/updateMembershipFeature/${id}`,
  },
  MembershipType: {
    list: "admin/getAllMembershipTypes",
    add: "admin/addMembershipType",
    single: "admin/membershipType",
    update: (id?: string) => `admin/updateMembershipType/${id}`,
  },
  Subscription: {
    list: "admin/getAllSubscribtion",
    single: "admin/getSubscriptionDetails",
    transactions: "admin/getAllTransactions",
    add: "admin/addMembershipType",
    update: (id?: string) => `admin/updateMembershipType/${id}`,
  },
  quotes: {
    list: "admin/getQuotes",
    single: "admin/getSingleQuote",
    add: "admin/addQuote",
    delete: "admin/deleteQuote",
    update: (id?: string) => `admin/updateQuote/${id}`,
  },
  favorite: {
    list: "admin/getUserFavorites",
  },
  wishlist: { list: "admin/getUserWishlist" },
  event: { list: "admin/getEvents" },
  accessLevel: {
    teamsList: "admin/teams",
    singleTeam: "admin/team",
    addTeam: "admin/team",
    addMemberToTeam: "admin/team",
    deleteMemberFromTeam: "admin/team",
    updateMemberRole: "admin/team",

    roleList: "admin/roles",
    addRole: "admin/role",
    deletePermission: "admin/role",
    addPermission: (id?: string) => `admin/role/${id}`,
  },
  communications: {
    list: "admin/communications",
    single: "admin/communication",
    add: "admin/communication",
    update: (id?: string) => `admin/communication/${id}`,
    addComment: (id?: string) => `admin/communication/${id}/comment`,
  },
};
