import { BaseResponse } from "../@types";

export interface CategoryResponse extends BaseResponse {
  data: CategoryType;
}

export type CategoryType = {
  children: ChildrenType[];
  _id: string;
  published: boolean;
  name: string;
  viewName: string;
  order: number;
  description: string;
  mainPhoto: MainPhoto;
  parent?: string;
  position?: [];
  icon?: string;
  category?: any;
};

export type ChildrenType = {
  published: boolean;
  name: string;
  viewName: string;
  order: number;
  description: string;
  mainPhoto: MainPhoto;
  parent?: string;
  position?: [];
  icon?: string;
  children: [];
  _id: string;
};
export type MainPhoto = {
  _id: string;
  fileUrl: string;
  caption: string;
  seoTitle: string;
  file?: string;
  alterText?: string;
  title?: string;
  description?: string;
};

export const USER_TOKEN_LOCALSTORAGE = "userTokenKeys";
export function responseToForm(form: any) {
  return {
    ...form,
  };
}
